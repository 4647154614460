<template>
  <div class="profile-user-points-container">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <CategoryTitle :category="category" />

    <category-block
      :target="category"
      :container="false"
      position="position3"
      class="category-block category-block-3"
    />

    <category-block
      :target="category"
      :container="false"
      position="position4"
      class="category-block category-block-4"
    />

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
    />

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.profile-user-points-container {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixins from "~/mixins/category";

export default {
  name: "ProfilePoints",
  components: {
    CategoryTitle
  },
  mixins: [categoryMixins],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  async mounted() {}
};
</script>
